import $ from "jquery";
import CoreAnimation from "../animations/CoreAnimation";
class FlatDetails {
  constructor() {
    this.init();
  }

  init() {
    const btns = document.querySelectorAll(".flat-loop .flat-short-info");
    this.setActiveFlat();

    if (btns) {
      for (let i = 0; i <= btns.length - 1; i++) {
        btns[i].addEventListener("click", e => {
          if (!e.target.closest(".local-card")) {
            e.preventDefault();
            const container = btns[i].closest(".flat-loop");
            const flatID = btns[i].getAttribute("data-flat-id");
            const resultContainer = container.querySelector(".flat-details");
            const loader = container.querySelector(".loader");

            history.pushState(null, null, `#flat-${flatID}`);

            this.getFlatDetails(flatID, container, resultContainer, loader);
          }
        });
      }
    }
  }

  setActiveFlat() {
    if (window.location.hash) {
      const container = document.querySelector(window.location.hash);
      if (container) {
        const flatID = window.location.hash.replace(/^\D+/g, "");
        const resultContainer = container.querySelector(".flat-details");
        const loader = container.querySelector(".loader");
        this.getFlatDetails(flatID, container, resultContainer, loader);
      }
    }
  }

  getFlatDetails(flatID, container, resultContainer, loader) {
    if (resultContainer) {
      if (resultContainer.innerHTML == "") {
        $.ajax({
          method: "POST",
          url: jsData.ajaxURL,
          data: {
            action: "get_flat_details",
            flat_ID: flatID
          },
          beforeSend: () => {
            loader.classList.add("active");
          },
          success: response => {
            resultContainer.innerHTML = response;
            container.classList.toggle("toggled");
            CoreAnimation.slideToggle(resultContainer);
            
          },
          error: error => {
            console.log("error", error);
          },
          complete: () => {
            loader.classList.remove("active");
            const form = container.querySelector("form");
            wpcf7.init(form);
            if (wpcf7.cached) {
              wpcf7.refill(form);
            }
            form.querySelector(
              'input[name="site-url"]'
            ).value = `${window.location.href}`;
          }
        });
      } else {
        container.classList.toggle("toggled");
        CoreAnimation.slideToggle(resultContainer);
      }
    }
  }
}

export default FlatDetails;
