//js
require("./js/popup");
require("./js/slider");
require("./js/scroll");
require("./js/helper");
require("./js/navigation");
require("./js/maps");
require("./js/main");

//fonts
require("./fonts/avenir/stylesheet.css");

// css
import "normalize.css";
require("./sass/style.scss");
