const modal = document.getElementById("popup");
const span = document.getElementsByClassName("close")[0];
const content = document.getElementById("modal-content");

span.onclick = function() {
    modal.style.top = "-1000px";
};

window.onclick = function(event) {
    if (event.target === modal) {
        modal.style.top = "-1000px";
    }
};
